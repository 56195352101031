import React from 'react';
import { isMobile } from 'mobile-device-detect';
import classnames from 'classnames/dedupe'; // FIXME Look into using just one sort of lib like this
import safeAreaInsets from 'safe-area-insets';
import './style.scss';

function PageWrap({ className, children, style, paddedStyle }) {
  const bottomInset = safeAreaInsets.bottom;
  // FIXME Look into using something like https://www.npmjs.com/package/react-div-100vh to wrap all views - may even want it in an upper component
  return (
    <div className={classnames('rui-page rui-page-react content-wrap', className)} style={{ ...style, paddingBottom: bottomInset }}>
      <div className="padded-content" style={{ marginTop: isMobile ? -16 : 40, ...paddedStyle }}>
        { children }
      </div>
    </div>
  );
}

export default PageWrap;
