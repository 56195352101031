import './style.scss';
import classnames from 'classnames/dedupe';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Toast, ToastBody } from 'reactstrap';
import TimeAgo from 'react-timeago';
import { isMobile } from 'mobile-device-detect';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/styles';
import {
  addToast as actionAddToast,
  removeToast as actionRemoveToast,
} from '../../actions';
import Icon from '../Icon';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Transducer } from '../../utils/transducer';

const snackbarStyles = {
  default: {
    backgroundColor: Transducer.getPreference('dark_mode') === 'true' ? '#3e4044 !important' : '#17191d !important',
    color: '#ffffff !important',
  },
  warning: {
    backgroundColor: '#d6c290 !important',
    color: '#17191d !important',
  },
  info: {
    backgroundColor: '#2196f3 !important',
    color: '#ffffff !important',
  },
  success: {
    backgroundColor: '#28a745 !important',
    color: '#ffffff !important',
  },
  error: {
    backgroundColor: '#2196f3 !important',
    color: '#ffffff !important',
  },
};

class PageToasts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hiddenToasts: {},
    };
    this.timerToasts = {};

    this.maybePrepareToastsTimeout = this.maybePrepareToastsTimeout.bind(this);
    this.hideToast = this.hideToast.bind(this);
  }

  componentDidMount() {
    const { auth, addToast } = this.props;

    // Startup toast when already logged in
    if (auth.token) {
      setTimeout(() => {
        addToast({
          title: 'Let\'s get started',
          content: (
            <>
              {'Welcome to'}
              { ' ' }
              <strong>Boxpressd.</strong>
              { ' ' }
              {'Click here for a tour.'}
            </>
          ),
          // current date's milliseconds - 1,000 ms * 60 s * 60 mins * 24 hrs * (# of days beyond one to go back)
          time: new Date(new Date() - (1000 * 60 * 60 * 24 * 15)),
          duration: 8000,
        });
      }, 2000);
    }
  }

  componentDidUpdate(prevProps) {
    const { auth, addToast } = this.props;

    if (auth.token !== prevProps.auth.token) {
      // logged in toast.
      if (auth.token) {
        // addToast({
        //   title: 'Successfully Logged In',
        //   content: (
        //     <>
        //       Welcome to
        //       { ' ' }
        //       <strong>Boxpressd.</strong>
        //       { ' ' }
        //       Take a quick tour of our features.
        //     </>
        //   ),
        //   time: new Date(),
        //   duration: 4000,
        // });

        // logged out toast.
      } else {
        addToast({
          title: 'Successfully Logged Out',
          content: 'You can test Sign In and Sign Out forms with validation.',
          time: new Date(),
          duration: 4000,
          color: 'danger',
        });
      }
    }

    // this.maybePrepareToastsTimeout();
  }

  maybePrepareToastsTimeout() {
    const { toasts } = this.props;
    const { timerToasts, hideToast } = this;

    Object.keys(toasts).forEach((uid) => {
      const toast = toasts[uid];

      if (toast.duration && !timerToasts[uid]) {
        timerToasts[uid] = true;
        setTimeout(() => {
          hideToast(uid);
        }, toast.duration);
      }
    });
  }

  hideToast(uid) {
    const { removeToast } = this.props;

    // hide toast.
    this.setState({
      hiddenToasts: {
        ...this.state.hiddenToasts,
        [uid]: true,
      },
    }, () => {
      // completely remove toast from store.
      setTimeout(() => {
        removeToast(uid);
      }, 600);
    });
  }

  render() {
    const { classes, toasts } = this.props;
    const { hiddenToasts } = this.state;

    console.log('Got toasts...');
    console.log(toasts);

    if (isMobile) {
      return (
        <div className="rui-toast-container" style={{ zIndex: 100000 }}>
          { Object.keys(toasts).reverse().map((uid) => {
            const toast = toasts[uid];
            return (
              <Snackbar
                open={!hiddenToasts[uid]}
                onClose={() => this.hideToast(uid)}
                autoHideDuration={toast.duration || 4000}
                ContentProps={{
                  'aria-describedby': 'snackbar-fab-message-id',
                  role: 'alertdialog',
                  classes: {
                    root: classes[toast.color || 'default'],
                  },
                }}
                // message={toast.content}
                action={toast.action}
              >
                <SnackbarContent
                  message={toast.content}
                  // TODO Base the color off whether dark mode is enabled or not?
                  style={{ backgroundColor: '#d6c290', color: '#2b2b2b' }}
                />
              </Snackbar>
            );
          }) }
        </div>
      );
    }
    return (
      <div className="rui-toast-container" style={{ zIndex: 100000 }}>
        { Object.keys(toasts).reverse().map((uid) => {
          const toast = toasts[uid];
          return (
            <Toast
              key={uid}
              className={classnames('rui-toast', `toast-${toast.color}`)}
              isOpen
              // isOpen={!hiddenToasts[uid]}
            >
              <div className="toast-header">
                { toast.title ? (
                  <h5 className="mr-auto mnb-2">{ toast.title }</h5>
                ) : '' }
                { toast.time ? (
                  <small className="toast-date">
                    <TimeAgo date={toast.time} />
                  </small>
                ) : '' }
                { toast.closeButton ? (
                  <button
                    type="button"
                    className="ml-15 mnt-4 mnr-4 toast-close close"
                    aria-label="Close"
                    onClick={() => {
                      this.hideToast(uid);
                    }}
                  >
                    <Icon name="x" />
                  </button>
                ) : '' }
              </div>
              { toast.content ? (
                <ToastBody>
                  { toast.content }
                </ToastBody>
              ) : '' }
            </Toast>
          );
        }) }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
    toasts: state.get('toasts'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addToast: (data) => dispatch(actionAddToast(data)),
    removeToast: (id) => dispatch(actionRemoveToast(id)),
  };
}

export default withStyles(snackbarStyles)(connect(mapStateToProps, mapDispatchToProps)(PageToasts));
